<template>
  <ListPageContainer :title="$t('announce.events')">
    <div class="filters">
      <ItemsListFilter v-if="currentTagsSet.length > 0"
                       :current-filters="currentTagsSet"
                       :active-filter="activeCategoryIndex"
                       @filter="filterByCategory"
      />

      <ItemsListFilter v-if="currentTagsDateSet.length > 0"
                       :current-filters="currentTagsDateSet"
                       :active-filter="activeDateIndex"
                       :pre-selected="currentScheduleFilter"
                       @filter="filterByDate"
      />
    </div>

    <r-row class="px-4" wrap v-if="currentDataSet.length > 0 && !loading" :row-gap="{ widest: 24, wide: 20, middle: 16, narrow: 16 }">
      <r-col v-for="item in currentDataSet" :key="item.id" :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <LazyAnnouncement :data="item" @goto="goToItem(item)" />
      </r-col>
    </r-row>

    <NothingFound v-else-if="currentDataSet.length === 0 && !loading"/>

    <ListLoading v-else/>
  </ListPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import NothingFound from "@/components/Miscellaneous/NothingFound";
import ListLoading from "@/components/Miscellaneous/ListLoading";
import ItemsListFilter from "@/components/Filters/ItemsListFilter";
import ListPageContainer from "@/components/Containers/ListPageContainer";
import LazyAnnouncement from "@/components/LazyAnnouncement/LazyAnnouncement";

export default {
  name: "EventsListView",

  components: {
    LazyAnnouncement,
    NothingFound,
    ListLoading,
    ItemsListFilter,
    ListPageContainer,
  },

  mounted() {
      this.initialEventsSet();
  },

  computed: {
    ...mapGetters(['GET_EVENTS', 'GET_EVENTS_CATEGORIES', 'GET_FILTERED_EVENTS', 'GET_SINGLE_CITY']),

    currentDataSet() {
      return this.GET_FILTERED_EVENTS;
    },

    currentTagsSet() {
      return this.$makeCategoryTagsSet(this.GET_EVENTS_CATEGORIES);
    },

    currentTagsDateSet() {
      return this.$makeDatesTagsSet();
    },

    singleCityId() {
      return {
        cityId: this.GET_SINGLE_CITY ? this.GET_SINGLE_CITY[0].id : ''
      }
    }
  },

  data() {
    return {
      currentScheduleFilter: 'today',
      loading: false,

      activeDateIndex: 0,
      activeCategoryIndex: 0,

      filterDates: [],
      filterCategoryId: null,
    }
  },

  methods: {
    initialEventsSet() {
      this.loading = true;
      const todayDate = this.$moment().format('YYYY-MM-DD');
      this.filterDates = [
        todayDate,
        todayDate
      ];

      this.$store.dispatch('GET_FILTERED_EVENTS_FROM_SERVER',
          {
            date: this.filterDates,
            category: this.filterCategoryId,
            cityId: this.singleCityId?.cityId
          }).finally(() => {
        this.loading = false;
      });
    },
    filterByCategory({id, index}) {
      if (this.activeCategoryIndex === index) return;

      this.loading = true;
      this.activeCategoryIndex = index;
      this.filterCategoryId = id;

      if (this.filterDates.length === 0) {
        this.filterDates = [
          this.$moment().format('YYYY-MM-DD'),
          this.$moment().format('YYYY-MM-DD'),
        ];
      }

      if (id === 'all') {
        this.filterCategoryId = null;
      }

      this.$store.dispatch('GET_FILTERED_EVENTS_FROM_SERVER',
          {
            date: this.filterDates,
            category: this.filterCategoryId,
            cityId: this.singleCityId?.cityId
          }).finally(() => {
        this.loading = false;
      });
    },

    filterByDate({id, index}) {
      if (this.activeDateIndex === index) return;

      const todayDate = this.$moment().format('YYYY-MM-DD');
      const tomorrowDate = this.$moment().add(1, 'days').format('YYYY-MM-DD');

      this.loading = true;
      this.activeDateIndex = index;

      switch(id) {
        case 'today':
          this.filterDates = [
            todayDate,
            todayDate
          ];
          break;
        case 'tomorrow':
          this.filterDates = [
            tomorrowDate,
            tomorrowDate
          ];
          break;
        case 'weekend':
          this.filterDates = [
            this.$moment().isoWeekday(6).format('YYYY-MM-DD'),
            this.$moment().isoWeekday(7).format('YYYY-MM-DD')
          ];
          break;
        case 'month':
          this.filterDates = [
            this.$moment().format('YYYY-MM-DD'),
            this.$moment().endOf('month').format('YYYY-MM-DD')
          ];
          break;
        default:
          this.filterDates = [
            todayDate,
            todayDate
          ];
      }

      this.$store.dispatch('GET_FILTERED_EVENTS_FROM_SERVER',
          {
            date: this.filterDates,
            category: this.filterCategoryId,
            cityId: this.singleCityId?.cityId
          }).finally(() => {
        this.loading = false;
      });
    },

    goToItem(item) {
      this.$router.push({ path: `/events/${item.id}` });
    },
  }
}
</script>

<style lang="scss">
.filters {
  margin-bottom: 24px;

  .tags {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
