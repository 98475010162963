<template>
  <r-row class="tags px-5" type="filters" :wrap="false">
    <r-col v-for="(item, index) in currentFilters"
           :key="item.id"
           is-width-content
           class="px-1"
    >
      <r-button-tag
          :title="item.title"
          :color="item.color"
          v-model="currentValue"
          :val="item.id"
          nowrap
          @click.native="$emit('filter', { id: item.id, index: index })"
      />
    </r-col>
  </r-row>
</template>

<script>
export default {
  name: "ItemsListFilter",

  props: {
    currentFilters: {
      type: [Array, Object],
      default: () => [],
    },
    activeFilter: {
      type: [Number, String],
      default: () => 0,
    },
    preSelected: {
      type: [Number, String],
      default: () => 'all',
    },
  },

  data() {
    return {
      currentValue: this.preSelected,
    }
  },
}
</script>
